<template>
  <div>
    <!-- Preloader -->
    <!-- Navbar -->

    <Contact/>
    <!-- Main content -->
    <section class="slice bgf5">
      <div class="col-lg-12 t-center">
        <h3 class="h3-cp">SpeedDLink设计工具集成软件</h3>
        <p class="lead my-4">
          <a href="javascript:;">设计工具与系统无缝集成</a>
        </p>
        <p class="lead my-4" style="width: 80vw;margin: 0 auto;text-align: initial;color: #fff;">
          SpeedDLink设计工具集成软件是用于研发、设计过程中实现设计工具与SPLM系统无缝集成的配置化工具。支持市面上主流的CAD、CAE、EDA软件，如NX、CREO、CATIA、CADENCE、INVENTOR、ALTIUM等。通过使用SpeedDLink设计工具集成软件，可以降低二次开发成本，提高工具集成效率。
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="item in dataList" :key="item.alt">
          <img :alt="item.alt" :src="item.img"/>
          <p>{{ item.alt }}</p>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
        <div slot="button-prev" class="swiper-button-prev"></div>
        <div slot="button-next" class="swiper-button-next"></div>
      </swiper>
    </section>

    <section class="slice slice-lg" style="padding-top:30px;">
      <div class="container">
        <div class="col-lg-12 t-center">
          <h3>SpeedDLink产品优势</h3>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">开箱即用</h5>
                <p class=" mt-2 mb-0">
                  简易化配置<br/>
                  支持多种主流设计工具集成
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">强应用性</h5>
                <p class=" mt-2 mb-0">
                  集成工具配置化管理<br/>
                  客户端免安装<br/>
                  客户端同步更新
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">强扩展性</h5>
                <p class=" mt-2 mb-0">
                  支持工具集成二次开发<br/>
                  支持设计工具类型扩展
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>

export default {
  data(){
    return{
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 70,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataList: [
        {img: require('../img/index/4/pro1.png'), alt: '集成插件'},
        {img: require('../img/index/4/pro2.png'), alt: 'PCB'},
        {img: require('../img/index/4/pro3.png'), alt: '原理图'},
        {img: require('../img/index/4/pro4.png'), alt: 'Inventor'},
        {img: require('../img/index/4/pro5.png'), alt: 'autoCAD'},
        {img: require('../img/index/4/pro6.png'), alt: 'Creo'}
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/my.scss";

.bgf5{
  background: url('../img/login/3.jpg') no-repeat;
  background-size: 100% 100%;
}
</style>
